@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  // Convert trailwind bg-gray-800 to --toastify-color-dark
  --toastify-color-dark: rgb(31 41 55) !important;
  --toastify-color-light: rgb(229 231 235) !important;
  --toastify-color-success: #228d75 !important;
}

// @import 'react-date-range/dist/styles.css'; // main style file
// @import 'react-date-range/dist/theme/default.css'; // theme css file
@import 'react-modern-calendar-datepicker/lib/DatePicker.css';
@import 'react-toastify/dist/ReactToastify.css';
@import url("https://cdn.jsdelivr.net/npm/instantsearch.css@7.3.1/themes/reset-min.css");

$primary-color: #228d75;
@import 'loaders.css/src/loaders.scss';



html, body {
  height: 100vh;
}

.dashboard-body {
  height: calc(100% - 80px);
}

.scroll-shade {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 96px;
  background: rgb(0,0,0);
  background: linear-gradient(90deg, rgba(243,244,246,0) 0%, rgba(243,244,246,1) 100%);
}

.dark .scroll-shade {
  background: linear-gradient(90deg, rgba(17, 24, 39, 0) 0%, rgba(17, 24, 39, 1) 100%) !important;
}

.soft-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px
}

/* Track */
.soft-scrollbar::-webkit-scrollbar-track {
  background: rgba(243, 244, 246, 1);
}

.dark .soft-scrollbar::-webkit-scrollbar-track {
  background: rgba(17, 24, 39, 1);
}

/* Handle */
.soft-scrollbar::-webkit-scrollbar-thumb {
  background: #c2c3c5;
  border-radius: 8px;
}

.dark .soft-scrollbar::-webkit-scrollbar-thumb {
  background: rgb(53, 62, 75);
  border-radius: 8px;
}

/* Handle on hover */
.soft-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #aeafb1;
}

.dark .soft-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgb(68, 76, 88);
}

.loader-hidden {
  display: none;
}
.loader-active {
  display: block;
}

.progress-container {
  display:block;
  position: absolute;
  bottom: 50px;
  right: -30px;
  // bottom: 90px;
  // right: -80px;
  transform: rotate(-90deg);
    
  &.warning-progress {
    progress[value]::-webkit-progress-value {
      border-radius: 8px;
      background-color: rgb(234 179 8);
      // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
    }
  }

  &.danger-progress {
    progress[value]::-webkit-progress-value {
      border-radius: 8px;
      background-color: #ee5527;
      // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
    }
  }
}

progress[value]::-webkit-progress-bar {
  border-radius: 8px;
  background-color: currentColor;
  // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

progress[value]::-webkit-progress-value {
  border-radius: 8px;
  background-color: #228d75;
  // box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

.recharts-cartesian-grid-horizontal {
  line {
    opacity: 0.2;
  }
}

.recharts-text.recharts-cartesian-axis-tick-value {
  font-size: 12px;
  opacity: 0.8;
}

.Calendar__yearSelectorText:not(:disabled) {
  color: black;
}

.Calendar__monthSelectorItemText:not(:disabled) {
  color: black;
}